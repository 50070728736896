

jQuery.fn.rotate = (degrees) ->
  $(this).css({'transform' : 'translateX(-50%) rotate('+ degrees + 'deg)'});
  return $(this);


$(document).ready ->
  resizeAll = (firstTime) ->
    windowHeight = $(window).height();
    windowWidth = $(window).width();
    headerHeight = $('header').height();

    
    mobile = if windowWidth >= 768 then false else true
    desktop = !mobile

    #wrapperHeight = windowHeight;

    #$('#wrapper').css('minHeight', wrapperHeight);

  $(window).on 'resize', resizeAll
  $(window).resize()


  window.lastRotation = 0
  window.currentTimeout = false
  $('.wheel-turn').click ->
    rotate = (Math.floor(Math.random() * 6) + 1)  * 60
    window.lastRotation = window.lastRotation + (rotate) + 360 * 2
    window.currentPopup = (window.lastRotation % 360) / 60;

    $(this).rotate(window.lastRotation);

    if window.currentTimeout
      clearTimeout window.currentTimeout

    window.currentTimeout = setTimeout ->
      $('#wrapper-popup').fadeIn()
      $('[data-product]').hide()
      $('[data-product=' + window.currentPopup + ']').show()
      $('input[name=product]').val(window.currentPopup)

      if window.ga?
        window.ga('send', 'event', 'Roue', window.currentPopup);
    , 2500


  $('.popup-close').click ->
    $('#wrapper-popup').fadeOut()

  $('.popup-gcu').click ->
    $('#overlay').fadeIn()
    $('#wrapper-popup-cgu').fadeIn()
  $('#overlay').click ->
    $('#overlay').fadeOut()
    $('#wrapper-popup-cgu').fadeOut()

  ###
  # Form
  # Focus / Blur placeholder are in utils.coffee
  ###
  $('form').on 'submit', (e) ->
    e.preventDefault();
    re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    test = true
    $form = $(this)
    $form.find('input,span,checkbox').removeClass 'error'

    $form.find('input[type=text].required, select.required').each ->
      if $(this).val() == $(this).data('placeholder') || $(this).val() == ''
        $(this).addClass 'error'
        test = false
      true

    ###
    if $form.find('input').val() == '' or $form.find('input').val() == $form.find('input').data('placeholder')
      $form.find('input').addClass 'error'
      test = false
    console.log test
    ###
    if !re.test($form.find('input[name=email]').val())
      $form.find('input[name=email]').addClass 'error'
      test = false

    #if !/\d\d\/\d\d\/\d\d\d\d/.test($form.find('input[name=birthday]').val())
    #  $form.find('input[name=birthday]').addClass 'error'
    #  test = false

    if test
      if window.ga?
        window.ga('send', 'event', 'Minisite', 'Formulaire enregistré');
        if $form.find('#nl').is(':checked')
          window.ga('send', 'event', 'Minisite', 'Inscription NL');
      $.post $(this).attr('action'), {
        #firstname: $form.find('[name=firstname]').val()
        #lastname: $form.find('[name=lastname]').val()
        email: $form.find('[name=email]').val()
        product: $form.find('[name=product]').val()
        #sex: $form.find('[name=sex]').val()
      }, ->
        alert 'Merci pour votre inscription'
        return
    false

